import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Supervision" />

    <div>
      <h1>
        Super<span className="text-blue-600">vision</span>
      </h1>

      <p>
        In supervision I use the person centred approach in order to promote a
        collaborative relationship where we work to ensure the safety of your
        clients and to encourage growth within your professional practice.
      </p>
      <p>
        The initial meeting, lasting half an hour is free in order for you to
        decide if we can work together.
      </p>
      <p>
        Supervision is available at £55 for 1.5 hours, (self funding), £60.00
        (externally funded, due to the extra admin required), which is the minimum requirement of any counsellor
        working within the BACPs ethical code of practice.
      </p>
      <p>
        Please feel free to email enquiries@margaretheap.co.uk, or ring 07544
        269118 with any questions you may have, or to book an appointment.
      </p>
    </div>
  </Layout>
)

export default IndexPage
